import {
  useCreateDoctolibPatient,
  useDeleteDoctolibPatient,
  useMergeDoctolibPatient,
  useUpdateDoctolibPatient,
} from '../doctolib'

export const useDoctolibSubscribers = () => {
  useCreateDoctolibPatient()
  useUpdateDoctolibPatient()
  useDeleteDoctolibPatient()
  useMergeDoctolibPatient()
}

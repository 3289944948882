import { patientsKeys } from '../../queries/patients/patients.keys'
import { useSubscribeMutation } from '../mutation'
import { isDoctolibLoaded } from '../../../state/doctolib'
import { Patient } from '../../../model/Patient'
import { MergeVariable } from '../../queries/patients/useMergePatient.query'
import { doctolibZipperMergePatient } from '../../../misc/Doctolib/doctolib.actions'

export const useMergeDoctolibPatient = () => {
  useSubscribeMutation<Patient, MergeVariable>(patientsKeys.merge, (_mutationData, variables) => {
    if (isDoctolibLoaded) {
      console.log(
        `Doctolib: Merge patient with patientId ${variables.patientId} and patientToMergeId ${variables.patientToMergeId}`,
      )
      doctolibZipperMergePatient(variables.patientToMergeId, _mutationData)
    }
  })
}

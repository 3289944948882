import React, { FunctionComponent, useCallback, useState } from 'react'
import { HealthDataModalProps } from './HealthDataModal.model'
import { HealthDataType, HealthDataField } from '../../../model/HealthData'
import { RoundedButton, Modal, ActionModalLayout } from '../../shared'
import { HealthDataModalHistory } from '../HealthDataModalHistory'
import classNames from 'classnames/bind'
import { HealthDataMedicalInformations } from './HealthDataMedicalInformations'
import { serializeModuleDataForm } from '../../../misc/healthData.utilities'
import {
  useGetPatientHealthDataTypes,
  useCreatePatientHealthData,
} from '../../../hooks/queries/patientHealthData'
import styles from './HealthDataModal.module.scss'

const cx = classNames.bind(styles)

export const HealthDataModal: FunctionComponent<HealthDataModalProps> = ({
  onClose,
  patient,
  ...restProps
}) => {
  const [selectedHealthDataType, setSelectedHealthDataType] = useState<HealthDataType>()
  const [inputChanged, setInputChanged] = useState(false)
  const [validation, setValidation] = useState<Record<string, boolean | undefined>>({
    weight: undefined,
    size: undefined,
    dfg: undefined,
  })
  const [fields, setFields] = useState<HealthDataField[]>()

  const { indexedTypes } = useGetPatientHealthDataTypes()
  const { mutate: createHealthData } = useCreatePatientHealthData()
  const showHistory =
    selectedHealthDataType && selectedHealthDataType !== indexedTypes.pregnancy_entity

  const handleOnClose = useCallback(() => {
    setSelectedHealthDataType(undefined)
    onClose()
  }, [onClose])

  const handleSave = useCallback(() => {
    if (fields) {
      const data = serializeModuleDataForm(fields)
      createHealthData({ patientId: patient.id, healthData: data })
    }
    handleOnClose()
  }, [createHealthData, fields, handleOnClose, patient.id])

  return (
    <Modal {...restProps} onClickOutside={handleOnClose}>
      <ActionModalLayout
        title="Édition des données médicales"
        onClose={handleOnClose}
        actions={
          <div className={styles.actions}>
            <RoundedButton
              label="Annuler"
              size="micro"
              theme="transparent-dark"
              onClick={onClose}
              testId="health_data-form-cancel"
            />
            <RoundedButton
              label="Enregistrer"
              size="micro"
              onClick={handleSave}
              disabled={!inputChanged || Object.values(validation).some((value) => value === false)}
              testId="health_data-form-save"
            />
          </div>
        }
      >
        <div className={styles.content}>
          <div className={styles.panel}>
            <HealthDataMedicalInformations
              patient={patient}
              indexedTypes={indexedTypes}
              setInputChanged={setInputChanged}
              setSelectedHealthDataType={setSelectedHealthDataType}
              setValidation={setValidation}
              validation={validation}
              setFields={setFields}
            />
          </div>
          {showHistory && (
            <div className={cx(styles.panel, styles.history)}>
              <div className={styles.column}>
                <HealthDataModalHistory
                  healthDataType={selectedHealthDataType}
                  patientId={patient.id}
                />
              </div>
            </div>
          )}
        </div>
      </ActionModalLayout>
    </Modal>
  )
}

import { DocumentInstance } from '../../../model/DocumentInstance'
import { useDispatch } from 'react-redux'
import { RenderedPdf, useRenderPdfFromDocuments } from './useRenderPdfFromDocuments.query'
import { chunk } from 'lodash'
import { useCallback } from 'react'
import { domainMailActions } from '../../../store/domain/mail'
import { sendDocuments } from '../../../store/renderer'
import { addValid } from '../../../store/message'

type SendableDocument = Pick<DocumentInstance, 'id' | 'type' | 'title'>

export type SendableDocumentVariable = {
  documents: SendableDocument[]
}

const isLinkedDocument = (document: SendableDocument) => document.type === 'farte'

export const useSendDocuments = () => {
  const dispatch = useDispatch()

  const send = useCallback(
    (renderedPdf: RenderedPdf) => {
      dispatch(
        domainMailActions.setFilesToSend({
          files: [new File([renderedPdf.blob], renderedPdf.fileName || 'Document')],
        }),
      )
    },
    [dispatch],
  )

  const { render } = useRenderPdfFromDocuments({ onSuccess: send })

  return ({ documents }: SendableDocumentVariable) => {
    dispatch(addValid("Documents en cours d'ajout"))

    const linkedDocuments: SendableDocument[] = documents.filter(isLinkedDocument)
    const separateDocuments: SendableDocument[] = documents.filter(
      (document) => !linkedDocuments.includes(document),
    )

    const toRenderDocuments = linkedDocuments.length
      ? [linkedDocuments, ...chunk(separateDocuments, 1)]
      : chunk(separateDocuments, 1)

    toRenderDocuments.forEach((documents: SendableDocument[]) =>
      render({
        documents: documents.map((document) => ({
          documentId: document.id,
          type: document.type,
        })),
        fileName: !isLinkedDocument(documents[0]) ? documents[0].title : null,
      }),
    )

    // Call legacy to build mail subject
    dispatch(
      sendDocuments(
        linkedDocuments.map((document) => ({
          documentId: document.id,
          type: 'farte',
        })),
      ),
    )
  }
}

import { FC, useCallback } from 'react'
import { isDefined } from '../../../../../../misc/functions.utilities'
import { Icon, Chip, HorizontalScroller } from '../../../../../shared'
import { daytimeLabels, dayTimes } from '../../PosologyDetail.model'
import { MomentDosageFieldProps } from './MomentDosageField.model'
import { DAY_PARITIES, dayParitiesLabel, PosologyDosages } from '../../../../../../model/Posology'
import { DosageQuantityInput } from '../DosageQuantityInput'

export const MomentDosageField: FC<MomentDosageFieldProps> = ({
  dosage,
  momentDosage,
  disabled,
  index,
  divisibility,
  drugs,
  setMomentDosage,
}) => {
  const setCurrentDosage = useCallback(
    (dosage: Partial<PosologyDosages>) => {
      setMomentDosage(
        momentDosage.map((currentDosage, currentIndex) => {
          if (index === currentIndex) {
            return { ...currentDosage, ...dosage }
          }
          return currentDosage
        }),
      )
    },
    [index, momentDosage, setMomentDosage],
  )

  const handleDayParityChange = useCallback(
    (value) => {
      if (isDefined(momentDosage[index])) {
        const currentParity = momentDosage[index].dayParity
        setCurrentDosage({ dayParity: currentParity === value ? undefined : value })
      }
    },
    [index, momentDosage, setCurrentDosage],
  )

  return (
    <div className="border-b border-shades-5 mb-6">
      <div className="mb-4">
        {dosage.quantities.map((quantity) => (
          <div className="mb-4" key={quantity.id}>
            <DosageQuantityInput
              label={`Quantité ${
                drugs.length > 1 ? drugs.find((drug) => drug.id === quantity.id)?.name : ''
              }`}
              quantityValue={quantity.value}
              divisibility={divisibility}
              disabled={disabled}
              onChange={(value) =>
                setCurrentDosage({
                  quantities: dosage.quantities.map((_quantity) =>
                    _quantity.id === quantity.id ? { ...quantity, value } : _quantity,
                  ),
                })
              }
            />
          </div>
        ))}
        {index !== 0 && (
          <div
            className="pt-1.5 cursor-pointer"
            onClick={() =>
              setMomentDosage(momentDosage.filter((_, currentIndex) => currentIndex !== index))
            }
          >
            <Icon icon="trash" size="nano" />
          </div>
        )}
      </div>
      <HorizontalScroller
        renderTitle={() => (
          <span className="text-xs text-shades-4 font-medium">Moment de la journée</span>
        )}
      >
        <div className="flex flex-row pt-2 pb-4 space-x-2">
          {dayTimes.map((time) => (
            <Chip
              colorPreset="dark"
              label={daytimeLabels[time] ?? time}
              key={time}
              onClick={() => setCurrentDosage({ moment: time })}
              selected={dosage.moment === time}
              renderLabel={(label) => <span>{label}</span>}
              disabled={disabled || momentDosage.some((dosage) => dosage.moment === time)}
              testId={`posology-moment-dosage-time-${time}`}
            />
          ))}
        </div>
      </HorizontalScroller>
      <HorizontalScroller
        renderTitle={() => (
          <span className="text-xs text-shades-4 font-medium">Parité journalière</span>
        )}
      >
        <div className="flex flex-row pt-2 pb-4 space-x-2">
          {DAY_PARITIES.map((dayParity) => (
            <Chip
              colorPreset="dark"
              label={dayParitiesLabel[dayParity] ?? dayParity}
              key={dayParity}
              onClick={() => handleDayParityChange(dayParity)}
              selected={dayParity === dosage.dayParity}
              renderLabel={(label) => <span>{label}</span>}
              disabled={disabled}
              testId={`posology-moment-dosage-day-parity-${dayParity}`}
            />
          ))}
        </div>
      </HorizontalScroller>
    </div>
  )
}

export enum FeaturesType {
  agenda = 'agenda',
  vitalCardService = 'vitalCardService',
  drug = 'drug',
  distantForm = 'distantForm',
  mail = 'mail',
  mailAttachments = 'mailAttachments',
  dmp = 'dmp',
  adel = 'adel',
  dmpInApp = 'dmpInApp',
  mssAutoInsiFetch = 'mssAutoInsiFetch',
  ePrescription = 'ePrescription',
  insiValidation = 'insiValidation',
  cda = 'cda',
  segurDocumentName = 'segurDocumentName',
  mssSmtp = 'mssSmtp',
  gynecology = 'gynecology',
  mssExtractPatient = 'mssExtractPatient',
}

import { ApiResponse } from 'apisauce'
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects'
import { DocumentTemplate, DocumentTemplateSuggestionList } from '../../../model/DocumentTemplate'
import { documentTemplatesActions } from '../../cache/documentTemplates'
import {
  cloneDocumentTemplate,
  getDocumentTemplatesSuggestions,
} from '../../cache/documentTemplates/api'
import { currentUserSelector } from '../../domain/me'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { isSuccessfulApiResponse } from '../../restux/cache/restuxCacheSagas.factory'
import { generateIdentifier } from '../../restux/restux.utilities'
import {
  craftAMinimalTypescriptCompatibleDocumentUpdate,
  documentWithSingleSourceOfTruthMapper,
  PendingVariables,
  pendingVariablesSelector,
} from '../editor'
import { domainDocumentTemplatesActions } from './documentTemplates.actions'
import { DomainDocumentTemplateActionTypes } from './documentTemplates.model'
import { valueToJSON } from '@follow/farte'
import { customHistory } from '../../../history'
import { inUseDocumentTemplateSelector } from '../../ui/bottomPanelDocumentTemplates/bottomPanelDocumentTemplates.selectors'
import { addResponseError } from '../../message'
import { AnyAction } from 'redux'

function* duplicateDocumentTemplateSagaWorker({
  documentTemplateId,
  targetUserId,
}: ReturnType<typeof domainDocumentTemplatesActions.duplicateDocumentTemplate>) {
  const user: ReturnType<typeof currentUserSelector> = yield select(currentUserSelector)
  let userId = targetUserId
  if (targetUserId === undefined && user) {
    userId = user.id
  }
  const response: ApiResponse<DocumentTemplate> = yield call(
    cloneDocumentTemplate,
    documentTemplateId,
    userId,
  )

  if (!isSuccessfulApiResponse(response)) {
    yield put(documentTemplatesActions.actions.dispatchError(response, {}))
  } else if (response.data && typeof targetUserId === 'undefined') {
    // Mise à jour du cache si l'utilisateur cible est l'utilisateur courant
    yield put(
      documentTemplatesActions.actions.storeSetItemDetails(response.data, {
        refetchList: true,
      }),
    )
    // Ouverture du document dupliqué
    customHistory.navigate(`/managers/documents/${response.data.id}`)
  }
}

function* duplicateDocumentTemplateSagaWatcher() {
  yield takeEvery(DomainDocumentTemplateActionTypes.DUPLICATE, duplicateDocumentTemplateSagaWorker)
}

function* updateDocumentTemplateValueSagaWorker({
  documentTemplateId,
  documentTemplateValue,
  successCallback,
}: ReturnType<typeof domainDocumentTemplatesActions.updateDocumentTemplateValue>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.updateDocumentTemplateSaga)
  const currentJsonValue = valueToJSON(documentTemplateValue)
  const updates = documentWithSingleSourceOfTruthMapper(currentJsonValue)
  const inUseDocumentTemplate: DocumentTemplate | null = yield select(inUseDocumentTemplateSelector)
  if (inUseDocumentTemplate && inUseDocumentTemplate.type === 'farte') {
    // On construit une instance de document suffisante pour l'API
    const pendingVariables: PendingVariables = yield select(pendingVariablesSelector)

    const craftedDocumentTemplate = craftAMinimalTypescriptCompatibleDocumentUpdate(
      updates,
      inUseDocumentTemplate,
      pendingVariables,
    ) as unknown as Partial<DocumentTemplate>

    yield put(
      documentTemplatesActions.actions.apiUpdateItem(documentTemplateId, craftedDocumentTemplate, {
        identifier: taskIdentifier,
      }),
    )

    const { succeed }: ReturnType<typeof documentTemplatesActions.actions.storeSetItemDetails> =
      yield race({
        succeed: take(
          (action: AnyAction) =>
            action.type === documentTemplatesActions.types.STORE_SET_ITEM_DETAILS &&
            action.identifier === taskIdentifier,
        ),
        failed: take(documentTemplatesActions.types.DISPATCH_ERROR),
      })

    if (!succeed) {
      return
    }

    if (successCallback) {
      successCallback(succeed.item)
    }
  }
}

function* updateDocumentTemplateValueSagaWatcher() {
  yield takeEvery(
    DomainDocumentTemplateActionTypes.UPDATE_VALUE,
    updateDocumentTemplateValueSagaWorker,
  )
}

function* getDocumentTemplatesSuggestionWorker({
  medicalEventId,
}: ReturnType<typeof domainDocumentTemplatesActions.getDocumentTemplatesSuggestion>) {
  const response: ApiResponse<DocumentTemplateSuggestionList> = yield call(
    getDocumentTemplatesSuggestions,
    medicalEventId,
  )
  if (response.ok && response.data) {
    yield put(
      domainDocumentTemplatesActions.setDocumentTemplatesSuggestion(medicalEventId, response.data),
    )
  } else {
    yield put(addResponseError(response))
  }
}

function* getDocumentTemplatesSuggestionWatcher() {
  yield takeEvery(
    DomainDocumentTemplateActionTypes.GET_SUGGESTIONS,
    getDocumentTemplatesSuggestionWorker,
  )
}

export const domainDocumentTemplatesSagas = {
  getDocumentTemplatesSuggestionWatcher,
  duplicateDocumentTemplateSagaWatcher,
  updateDocumentTemplateValueSagaWatcher,
}

import { ExternalServices, FollowTypes, UserTypeId } from '@follow/cdk'
import { object, ObjectSchema, string, StringSchema } from 'yup'
import { ADMIN_ROLES, Roles } from './Roles'
import { PHONE_REGEX } from './Patient'
import { followAdminEmailRegex } from '../misc/regex'

export type UserType = FollowTypes.User.UserType
export type ExternalAccount = FollowTypes.User.ExternalAccount
export type AssistantPermissions = FollowTypes.User.AssistantPermissions
export type ImpersonatePermissions = FollowTypes.User.ImpersonatePermissions

export type LightUserBase = FollowTypes.User.LightUserBase
export type UserBase = FollowTypes.User.UserBase

export type LightUserDoctor = FollowTypes.User.LightUserDoctor
export type ExtendedUserDoctor = FollowTypes.User.ExtendedUserDoctor
export type UserDoctor = FollowTypes.User.UserDoctor

export type LightUserAssistant = FollowTypes.User.LightUserAssistant
export type LightAssistantWithPermissions = FollowTypes.User.LightAssistantWithPermissions
export type UserAssistant = FollowTypes.User.UserAssistant

export type LightUserAdmin = FollowTypes.User.LightUserAdmin
export type LightUserSuperAdmin = FollowTypes.User.LightUserSuperAdmin
export type UserAdmin = FollowTypes.User.UserAdmin
export type UserSuperAdmin = FollowTypes.User.UserSuperAdmin

export type LightUser = FollowTypes.User.LightUser
export type User = FollowTypes.User.User

export { UserTypeId, ExternalServices }

export const USER_TYPES: { [key in UserTypeId]: UserType } = {
  [UserTypeId.Chir]: { id: UserTypeId.Chir, title: 'Chirurgien' },
  [UserTypeId.Kine]: { id: UserTypeId.Kine, title: 'Kinésithérapeute' },
  [UserTypeId.Labo]: { id: UserTypeId.Labo, title: 'Laboratoire' },
  [UserTypeId.Medecin]: { id: UserTypeId.Medecin, title: 'Médecin' },
  [UserTypeId.Other]: { id: UserTypeId.Other, title: 'Autre' },
}

export enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export interface PasswordUpdate {
  actualPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

export const signatureSize = { width: 300, height: 225 }

// --- Validation ---

const phoneValidation = string().matches(PHONE_REGEX, 'Chiffres et "+" uniquement')
const numberRegex = /^\d+$/

export const userFormValidationSchema: ObjectSchema = object({
  firstName: string().required('Prénom manquant'),
  familyName: string().required('Nom de famille manquant'),
  sex: string().required('Sexe manquant'),
  email: string()
    .when('mainRole', {
      is: (val) => ADMIN_ROLES.includes(val),
      then: (schema: StringSchema) =>
        schema.matches(
          followAdminEmailRegex,
          `L’adresse email doit contenir le nom de domaine "follow.fr"`,
        ),
    })
    .email('Doit être un email valide')
    .required('Adresse email manquant'),
  mailizEmailAddress: string().nullable().email('Doit être un email valide'),
  phone: phoneValidation,
  subPhone: phoneValidation,
  speciality: object().nullable().notRequired(),
  profession: object()
    .nullable()
    .when(['mainRole', 'speciality'], {
      is: (mainRole, speciality) => mainRole === Roles.DOCTOR && !!speciality,
      then: (schema: StringSchema) => schema.required('Profession manquante'),
      otherwise: (schema: StringSchema) => schema.nullable().notRequired(),
    }),
  rppsNumber: string().when('mainRole', {
    is: Roles.DOCTOR,
    then: (schema: StringSchema) =>
      schema
        .matches(numberRegex, 'Le numéro RPPS doit être un nombre')
        .length(11, 'Le numéro RPPS doit avoir 11 caractères')
        .when('preferences.enabledFeatures.vitalCardService', {
          is: 'sephira',
          then: (schema: StringSchema) =>
            schema.required('Le numéro RPPS est obligatoire quand Follow Vitale est activé'),
        }),
    otherwise: (schema: StringSchema) => schema.nullable(),
  }),
  finessNumber: string().when('mainRole', {
    is: Roles.DOCTOR,
    then: (schema: StringSchema) =>
      schema
        .matches(numberRegex, 'Le numéro FINESS doit être un nombre')
        .length(9, 'Le numéro FINESS doit avoir 9 caractères'),
    otherwise: (schema: StringSchema) => schema.nullable(),
  }),
})

import { FeaturesType, FollowTypes } from '@follow/cdk'
import { DEFAULT_DOCUMENT_ALERTS_FILTERS } from './DocumentAlerts'
import { TrustedSource } from './Patient'
import { SORT_DIRECTION } from './Resource'

export type AgendaType = FollowTypes.Preferences.AgendaType
export type VitalCardServiceType = FollowTypes.Preferences.VitalCardServiceType
export type InsiValidationType = FollowTypes.Preferences.InsiValidationType
export type Features = FollowTypes.Preferences.Features
export type Preferences = FollowTypes.Preferences.Preferences
export type PreferencesDoctor = FollowTypes.Preferences.PreferencesDoctor
export type PreferencesAssistant = FollowTypes.Preferences.PreferencesAssistant
export type PreferencesAdmin = FollowTypes.Preferences.PreferencesAdmin
export type PreferenceKeys = FollowTypes.Preferences.PreferenceKeys
export { FeaturesType }

export enum PreferenceNames {
  consentText = 'tablet-consenttext',
  editorFontFamily = 'editor-font-family',
  defaultLayout = 'default-layout',
  enabledFeatures = 'features',
  drugSorting = 'drug-sorting',
  documentAlertsFilters = 'document-alerts-filters',
  useStellairAssistantAccount = 'use-stellair-assistant-account',
  otpEnabled = 'otp-enabled',
  trustedSourceAccepted = 'trusted-source-accepted',
  timeZone = 'time-zone',
  contextePS = 'contexte-ps',
  'default-layout' = 'defaultLayout',
  'tablet-consenttext' = 'consentText',
  'editor-font-family' = 'editorFontFamily',
  'features' = 'enabledFeatures',
  'drug-sorting' = 'drugSorting',
  'document-alerts-filters' = 'documentAlertsFilters',
  'use-stellair-assistant-account' = 'useStellairAssistantAccount',
  'otp-enabled' = 'otpEnabled',
  'trusted-source-accepted' = 'trustedSourceAccepted',
  'time-zone' = 'timeZone',
  'contexte-ps' = 'contextePS',
}

export const DEFAULT_FEATURES: Features = {
  agenda: undefined,
  vitalCardService: undefined,
  drug: false,
  distantForm: false,
  mail: false,
  mailAttachments: false,
  dmp: true,
  adel: false,
  dmpInApp: false,
  mssAutoInsiFetch: false,
  ePrescription: false,
  insiValidation: undefined,
  cda: false,
  segurDocumentName: false,
  mssSmtp: false,
  gynecology: false,
  mssExtractPatient: false,
}

export const DEFAULT_DOCTOR_PREFERENCES: PreferencesDoctor = {
  consentText: null,
  editorFontFamily: 'Montserrat',
  defaultLayout: null,
  enabledFeatures: DEFAULT_FEATURES,
  drugSorting: { direction: SORT_DIRECTION.ASC, sortBy: 'name' },
  documentAlertsFilters: DEFAULT_DOCUMENT_ALERTS_FILTERS,
  otpEnabled: true,
  trustedSourceAccepted: Object.values(TrustedSource).filter(
    (value) => value !== TrustedSource.TRUSTED_CONTACT,
  ),
  timeZone: 'Europe/Paris',
  contextePS: 1,
}

export const DEFAULT_ASSISTANT_PREFERENCES: PreferencesAssistant = {
  otpEnabled: false,
  useStellairAssistantAccount: false,
}

export const DEFAULT_ADMIN_PREFERENCES: PreferencesAdmin = {
  otpEnabled: false,
}

export const AGENDA_OPTIONS: Array<{ value: AgendaType | undefined; label: string }> = [
  { value: undefined, label: 'Aucun' },
  { value: 'doctolib', label: 'Doctolib' },
  { value: 'ordoclic', label: 'Ordoclic' },
  { value: 'agendize', label: 'Agendize' },
]

export const VITAL_CARD_SERVICE_OPTIONS: Array<{
  value: VitalCardServiceType | undefined
  label: string
}> = [
  { value: undefined, label: 'Aucun' },
  { value: 'stellair', label: 'Stellair' },
  { value: 'sephira', label: 'Sephira' },
]

export const INSI_VALIDATION_OPTIONS: Array<{
  value: InsiValidationType | undefined
  label: string
}> = [
  { value: undefined, label: 'Aucun' },
  { value: 'insiValidationRest', label: 'INSi Distant' },
  { value: 'insiValidationInApp', label: 'INSi in-app (INSi Consult)' },
]

export const MSS_PROTOCOLS: Array<{ value: boolean | undefined; label: string }> = [
  { value: false, label: 'WebService' },
  { value: true, label: 'SMTP/IMAP' },
]

import React, { FunctionComponent, useCallback, useMemo } from 'react'
import { LabeledButton } from '../../../../shared'
import { useNavigate } from 'react-router-dom'
import {
  MedicalEvent,
  MedicalEventDocumentType,
  TimelineEventType,
} from '../../../../../model/MedicalEvent'
import { getFileKind } from '../../../../../misc/files.utilities'
import { Header } from './Header'
import { Documents, DocumentItem } from './Documents'
import styles from './MedicalEventItem.module.scss'
import { getFullName } from '../../../../../misc/user.utilities'
import { DocumentVidalInfos } from '../../../../../model/DocumentInstance'
import { formatFr } from '../../../../../misc/date.utilities'
import { HeadlessEditor } from '@follow/elfe'
import { getDocOrFileAnchor } from '../../../../../misc/navTrans.utilities'
import { useWidthObserver } from '../../../../../hooks/utils'
import { breakMedium } from '../../../../../misc/responsive'
import { getCurrentPatient } from '../../../../../misc/currentPatient.utilities'
import { EventDocumentType } from '../../../PatientTransversalNavigation/PatientTransversalNavigationEventDetails/PatientTransversalNavigationEventDocHeader'

interface MedicalEventItemProps {
  medicalEvent: MedicalEvent
  type: TimelineEventType
  onNavigateToMedicalEvent: () => void
  onDeleteMedicalEvent: () => void
  onAddDocumentClick: () => void
  onDuplicateMedicalEventDocuments: () => void
  onPinMedicalEvent: (medicalEvent: MedicalEvent) => void
  onOwnerChange?: (medicalEvent: MedicalEvent) => void
}

const computeVidalInfos = (vidalInfos: DocumentVidalInfos) =>
  `${
    vidalInfos.securisationDate
      ? `Date de sécurisation : ${formatFr(new Date(vidalInfos.securisationDate), 'dd/MM/yyyy')}\n`
      : ''
  }Version Vidal : ${vidalInfos.version}\nVersion de sécurisation : ${
    vidalInfos.securisationVersion
  }\nDernière mise à jour : ${formatFr(
    new Date(vidalInfos.updatedAt),
    'dd/MM/yyyy',
  )}\nFin de licence : ${
    vidalInfos.licenseExpirationDate
      ? formatFr(new Date(vidalInfos.licenseExpirationDate), 'dd/MM/yyyy')
      : "Pas d'expiration"
  }`

export const MedicalEventItem: FunctionComponent<MedicalEventItemProps> = ({
  type,
  medicalEvent,
  onNavigateToMedicalEvent,
  onDeleteMedicalEvent,
  onAddDocumentClick,
  onDuplicateMedicalEventDocuments,
  onPinMedicalEvent,
  onOwnerChange,
}) => {
  const navigate = useNavigate()
  const currentPatient = getCurrentPatient()
  const isLowerThanBreakpoint = useWidthObserver(breakMedium)

  const {
    id: eventId,
    title,
    owner,
    locked,
    pinned,
    observation: observations,
    documents,
    files,
    tagIds,
    isEditable,
    isDeletable,
  } = medicalEvent

  const handleDocumentClick = useCallback(
    (type: MedicalEventDocumentType, id: number) => () => {
      const params = type === MedicalEventDocumentType.FW_DOCUMENT ? `docId=${id}` : `fileId=${id}`
      const anchor = getDocOrFileAnchor(id, type)

      if (isLowerThanBreakpoint) {
        navigate(`/patients/${currentPatient?.id}/medicalEvent/${eventId}?${params}`)
      } else {
        navigate(`browse_events/?eventId=${medicalEvent.id}${anchor}`)
      }
    },
    [currentPatient?.id, eventId, isLowerThanBreakpoint, medicalEvent.id, navigate],
  )

  const handleOpenChangeModal = useCallback(() => {
    if (onOwnerChange) {
      onOwnerChange(medicalEvent)
    }
  }, [medicalEvent, onOwnerChange])

  const canDuplicateDocuments = useMemo(
    () => isEditable && currentPatient?.isEditable,
    [currentPatient?.isEditable, isEditable],
  )

  const handleEdit = useCallback(
    (id: number, type: EventDocumentType) => () => {
      const params = type === EventDocumentType.Document ? `docId=${id}` : `fileId=${id}`
      navigate(`medicalEvent/${eventId}?${params}`)
    },
    [eventId, navigate],
  )

  return (
    <div
      className={styles.container}
      data-test-id={`timeline_item-medical_event-${medicalEvent.id}`}
    >
      <Header
        title={title}
        ownerName={getFullName(owner, true)}
        creatorName={getFullName(medicalEvent.creator, true)}
        tagIds={tagIds}
        locked={locked}
        pinned={pinned}
        medicalEventType={type}
        onPinMedicalEvent={() => onPinMedicalEvent(medicalEvent)}
        onClick={onNavigateToMedicalEvent}
        onEditMedicalEvent={isEditable ? onNavigateToMedicalEvent : undefined}
        onDeleteMedicalEvent={isDeletable ? onDeleteMedicalEvent : undefined}
        onDuplicateMedicalEventDocuments={
          canDuplicateDocuments ? onDuplicateMedicalEventDocuments : undefined
        }
        onOwnerChange={onOwnerChange ? handleOpenChangeModal : undefined}
      />
      <div className={styles.content}>
        {!!observations && (
          <div className={styles.observations}>
            <HeadlessEditor content={observations} themes={['no-paragraph-space']} />
          </div>
        )}
        <Documents>
          {documents &&
            documents.map(({ id, category, title, vidalInfos }) => (
              <DocumentItem
                key={id}
                documentId={id}
                category={category}
                documentName={title}
                documentInfos={vidalInfos ? computeVidalInfos(vidalInfos) : undefined}
                navigateToNavTrans={handleDocumentClick(MedicalEventDocumentType.FW_DOCUMENT, id)}
                navigateToEditor={handleEdit(id, EventDocumentType.Document)}
              />
            ))}
          {files &&
            files.map(({ id, visibleName, extension, critical }) => (
              <DocumentItem
                key={id}
                documentId={id}
                category={getFileKind(extension)}
                documentName={visibleName}
                navigateToNavTrans={handleDocumentClick(MedicalEventDocumentType.FILE, id)}
                colorPreset={critical ? 'danger' : undefined}
                navigateToEditor={handleEdit(id, EventDocumentType.File)}
              />
            ))}
          {isEditable && (
            <div className={styles.addDocumentButton}>
              <LabeledButton
                icon="add"
                label="Document"
                size="medium"
                onClick={onAddDocumentClick}
              />
            </div>
          )}
        </Documents>
      </div>
    </div>
  )
}

import React, { FunctionComponent, useMemo } from 'react'
import classNames from 'classnames/bind'
import {
  MedicalEventContentType,
  MedicalEventContentTypeIcon,
} from '../../../../../../../model/MedicalEvent'
import { DocumentCategoryColor } from '../../../../../../../model/DocumentCategory'
import { Icon } from '../../../../../../shared'
import { Colors, getColor } from '../../../../../../../colors'
import { ChipProps } from '../../../../../../shared/chip/Chip/Chip.model'
import { breakMedium } from '../../../../../../../misc/responsive'
import { useWidthObserver } from '../../../../../../../hooks/utils'
import styles from './DocumentItem.module.scss'

interface DocumentItemProps {
  documentId: number
  documentName: string
  documentInfos?: string
  category: MedicalEventContentType
  navigateToNavTrans: () => void
  navigateToEditor: () => void
  colorPreset?: ChipProps['colorPreset']
}

const cx = classNames.bind(styles)

export const DocumentItem: FunctionComponent<DocumentItemProps> = ({
  documentName,
  documentInfos,
  category,
  navigateToNavTrans,
  navigateToEditor,
  colorPreset,
}) => {
  const isLowerThanBreakpoint = useWidthObserver(breakMedium)
  const icon = MedicalEventContentTypeIcon[category]
  const docBorderColor = DocumentCategoryColor[category]
    ? DocumentCategoryColor[category]
    : DocumentCategoryColor.other

  const isDanger = useMemo(() => colorPreset === 'danger', [colorPreset])
  const iconColor = useMemo(() => (isDanger ? Colors.error : Colors.contentDark1), [isDanger])
  const borderColor = useMemo(
    () => getColor(isDanger ? Colors.error : docBorderColor),
    [docBorderColor, isDanger],
  )

  return (
    <div className={styles.container} {...(isLowerThanBreakpoint && { onClick: navigateToEditor })}>
      <div className={cx(styles.item, { danger: isDanger })} style={{ borderColor: borderColor }}>
        <div>
          <Icon size="nano" icon={icon} color={iconColor} title={documentInfos} />
        </div>
        <div className={styles.labelContent}>
          <span className={cx(styles.title, { danger: isDanger })}>{documentName}</span>
        </div>
        {isLowerThanBreakpoint && (
          <div className={styles.infoIcon}>
            {documentInfos && (
              <Icon size="micro" icon="infoCircle" color={Colors.dsDark4} title={documentInfos} />
            )}
          </div>
        )}
      </div>

      {!isLowerThanBreakpoint && (
        <div className={styles.hoveredItem}>
          <div onClick={navigateToEditor} className={styles.iconContainer}>
            <Icon size="nano" icon="editAlt" color={Colors.dsWhite} title="Éditer le document" />
          </div>
          <div onClick={navigateToNavTrans} className={styles.iconContainer}>
            <Icon
              size="nano"
              icon="eye"
              color={Colors.dsWhite}
              title="Accéder à la navigation transverse"
            />
          </div>
          {documentInfos && (
            <div className={`${styles.iconContainer} ${styles.iconData}`}>
              <Icon size="nano" icon="infoCircle" color={Colors.dsWhite} title={documentInfos} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mergePatients } from '../../../data/patients'
import { UserFriendlyError } from '../../../model/Error'
import { patientsKeys } from './patients.keys'
import { medicalEventsKeys } from '../medicalEvents/medicalEvents.keys'
import { Patient } from '../../../model/Patient'
import { patientImportantInformationsKeys } from '../patientImportantInformations/patientImportantInformations.keys'

export interface MergeVariable {
  patientId: number
  patientToMergeId: number
}

export const useMergePatient = () => {
  const queryClient = useQueryClient()
  const queryKey = patientsKeys.merge

  return useMutation<Patient, Error, MergeVariable>(
    queryKey,
    async ({ patientId, patientToMergeId }) => {
      const { data, ok } = await mergePatients(patientId, patientToMergeId)
      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La fusion des patients a échoué')
      }
      return data
    },
    {
      onSuccess: (_data, { patientId }) => {
        queryClient.invalidateQueries(medicalEventsKeys.lists)
        queryClient.invalidateQueries(patientsKeys.lists)
        queryClient.invalidateQueries(patientsKeys.detail(patientId))
        queryClient.invalidateQueries(patientImportantInformationsKeys.listAll(patientId))
      },
    },
  )
}
